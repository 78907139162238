<template>
  <div class="reporting">
    <!-- work on filters bar -->
    <div
      class="d-flex bg-white mb-2 p-3 rounded align--center datatable-controls"
    >
      <items-per-page
        :options="perPageOptions"
        :defaultPerPage="10"
        :label="$t('shared.table.per-page')"
        @items-per-page="onLimitChange"
      />
      <button
        class="mx-2 rounded btn btn-micro btn-primary"
        @click="openFilterbar()"
      >
        {{ $t('shared.table.filter-by') }}
      </button>
      <div class="d-flex additionalTableControl">
        <button
          class="rounded btn-with-icon-micro btn btn-micro btn-primary"
          @click="downloadReport"
        >
          <div class="btn-with-icon-content">
            <i class="fa fa-download"></i>
            {{ $t('call-reporting.export') }}
          </div>
        </button>
      </div>
    </div>

    <data-table
      ref="callsTable"
      :data="CDRs"
      :fields="translatedTableFields"
      :pagination="pagination"
      :loading="CDRsLoading"
      @changePage="onPageChange"
    >
      <template v-slot:status="{ row }">
        <call-status :rowData="row"></call-status>
      </template>
      <template v-slot:direction="{ row }">
        <call-direction :rowData="row"></call-direction>
      </template>
      <template v-slot:summary="{ row }">
        <div class="d-flex justify-content-center" v-if="row.callSummary">
          <b-button size="sm" class="p-2 text-sm" @click="showCallSummary(row)">
            <small>{{ $t('call-reporting.table.view-summary') }}</small>
          </b-button>
        </div>
      </template>
      <template v-slot:actions="{ row }">
        <actions :rowData="row"></actions>
      </template>
    </data-table>

    <app-filterbar>
      <template slot="filterbar-content">
        <div class="filterbar-input">
          <vue-flatpickr-component
            class="vuestic-date-picker"
            v-model="filters.dateRange"
            :config="{
              mode: 'range',
              maxDate: 'today'
            }"
          />
        </div>
        <div class="filterbar-input">
          <label
            v-for="callStatusOption in callStatusOptions"
            :for="callStatusOption.status"
            v-bind:key="callStatusOption.status"
            class="sub-filter"
          >
            <input
              type="checkbox"
              :id="callStatusOption.status"
              v-model="callStatusOption.search"
            />
            {{ callStatusOption.title }}
          </label>
        </div>
        <div class="w-100 text-center">
          <button
            class="btn btn-primary btn-sm filterbar-button"
            @click="applyFilters"
          >
            {{ $t('shared.filterBar.apply-filters') }}
          </button>
        </div>
      </template>
    </app-filterbar>

    <modal
      v-if="currentRow && currentRow.callSummary"
      :isOpen="callSummaryModalOpen"
      @dismiss="callSummaryModalOpen = false"
    >
      <h4 class="m-0">{{ $t('call-reporting.table.summary-modal.title') }}</h4>
      <div class="mb-4 w-100 comment">
        <label>
          {{ $t('call-reporting.table.summary-modal.comment') }}
        </label>
        <textarea
          class="form-control"
          rows="3"
          :value="currentRow.callSummary.comment"
          readonly
        ></textarea>
      </div>
      <div class="mb-4 w-100 added-by">
        <label>
          {{ $t('call-reporting.table.summary-modal.added-by') }}
        </label>
        <input
          class="form-control"
          :value="currentRow.callSummary.addedBy"
          readonly
        />
      </div>
      <div class="w-100 tags">
        <label class="m-0">
          {{ $t('call-reporting.table.summary-modal.tags') }}
        </label>
        <div class="post-call-tags">
          <b-badge
            class="mx-2"
            variant="primary"
            v-for="tag in currentRow.callSummary.postCallTags"
            :key="tag"
          >
            {{ tag }}
          </b-badge>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import AppFilterbar from '@/components/admin/app-filterbar/AppFilterbar'

import CallStatus from './components/call-status.vue'
import CallDirection from './components/call-direction.vue'
Vue.component('call-status', CallStatus)
Vue.component('call-direction', CallDirection)

import moment from 'moment'
import VueFlatpickrComponent from 'vue-flatpickr-component'
import CallActions from './components/call-actions'
import DataTable from '../../elements/Table.vue'
import CDRService from '../../services/cdr.service'
import ItemsPerPage from '../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/ItemsPerPage.vue'
Vue.component('actions', CallActions)
import Modal from '../../elements/Modal'

export default {
  components: {
    AppFilterbar,
    VueFlatpickrComponent,
    DataTable,
    ItemsPerPage,
    Modal
  },
  data() {
    return {
      fields: [
        {
          accessor: 'direction',
          header: 'call-reporting.table.call-direction'
        },
        { accessor: 'status', header: 'call-reporting.table.call-status' },
        {
          accessor: 'call_date_time',
          header: 'call-reporting.table.call-date'
        },
        { accessor: 'duration', header: 'call-reporting.table.call-duration' },
        { accessor: 'source', header: 'call-reporting.table.source' },
        { accessor: 'destination', header: 'call-reporting.table.destination' },
        { accessor: 'summary', header: 'call-reporting.table.summary' },
        { accessor: 'actions', header: 'call-reporting.table.Actions' }
      ],
      CDRs: [],
      pagination: {
        totalPages: 0,
        page: 1,
        perPage: 10
      },
      CDRsLoading: false,
      filters: {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        dateRange:
          moment().format('YYYY-MM-DD') +
          ' to ' +
          moment().format('YYYY-MM-DD'),
        callStatuses: ''
      },
      perPageOptions: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 100
        }
      ],
      callSummaryModalOpen: false,
      currentRow: null
    }
  },
  computed: {
    translatedTableFields() {
      return this.fields.map(field => ({
        ...field,
        header: this.$t(field.header)
      }))
    },
    callStatusOptions() {
      return [
        {
          title: this.$t('call-reporting.filters.statuses.answered'),
          status: 'ANSWERED',
          search: false
        },
        {
          title: this.$t('call-reporting.filters.statuses.failed'),
          status: 'FAILED',
          search: false
        },
        {
          title: this.$t('call-reporting.filters.statuses.no-answer'),
          status: 'NO ANSWER',
          search: false
        },
        {
          title: this.$t('call-reporting.filters.statuses.busy'),
          status: 'BUSY',
          search: false
        }
      ]
    },
    locale() {
      return Vue.i18n.locale()
    }
  },
  methods: {
    ...mapActions(['exportReport']),
    onPageChange(page) {
      this.pagination.page = page
      console.log(this.pagination)
      this.getCDRs({ page })
    },
    onLimitChange(limit) {
      this.pagination.limit = limit
      this.getCDRs({ limit })
    },
    onFiltersChange(filters) {
      this.filters = filters
      this.getCDRs({ filters })
    },
    async getCDRs({
      filters = this.filters,
      page = this.pagination.page,
      limit = this.pagination.limit
    } = {}) {
      this.CDRsLoading = true

      try {
        const { data, ...pagination } = await CDRService.getAgentCDRs(
          filters,
          page,
          limit
        )
        this.CDRs = data
        this.pagination = {
          ...this.pagination,
          ...pagination,
          totalPages: pagination.last_page
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.CDRsLoading = false
      }
    },
    async downloadReport() {
      try {
        this.downloadingReport = true
        await CDRService.exportAgentCDRs(this.filters)
        this.toast(this.$t('call-reporting.toasts.exported-successfully'))
      } catch (error) {
        console.log(error)
      } finally {
        this.downloadingReport = false
      }
    },
    async openFilterbar() {
      this.$store.dispatch('toggleFilterbar', true)
    },
    applyFilters() {
      this.filters.callStatuses = this.callStatusOptions
        .filter(option => option.search)
        .map(option => option.status)
      const [fromDate, toDate] = this.filters.dateRange.split(' to ')
      this.filters.fromDate = fromDate
      this.filters.toDate = toDate || fromDate

      this.onFiltersChange(this.filters)
    },
    showCallSummary(row) {
      this.callSummaryModalOpen = true
      this.currentRow = row
    }
  },
  mounted() {
    this.getCDRs()
  }
}
</script>
<style lang="scss" scoped>
h4 {
  margin-top: 0;
}
.form-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
<style lang="scss">
td.with-icon {
  position: relative;
}

.btn.loading {
  animation: loading 1s infinite;
}
@keyframes loading {
  0% {
    background-color: $darkblue;
  }
  25% {
    background-color: $blue;
  }
  50% {
    background-color: $darkblue;
  }
  75% {
    background-color: $blue;
  }
  100% {
    background-color: $darkblue;
  }
}
.reporting {
  .dropdown-menu-content {
    height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #0d4d80;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      outline: 0;
    }
  }
}
.post-call-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
